import React, { useContext, useState, useEffect } from 'react';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import DatePicker from './DatePicker';
import { EditForm, Layout } from '@custom';
import { InputText, Combobox } from '@components';
import { Translator, Configuration } from '@context';
import queries from '@queries';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import BookingCell from '../Calendar/BookingCell';

const capitalize = (str) => str[0].toUpperCase() + str.slice(1);

const BookingEdit = (props) => {
  const { id } = useParams();
  const translator = useContext(Translator.Context);
  const configuration = useContext(Configuration.Context);

  const ignoreGroups = false;

  const { data: booking, isLoading: isLoadingBooking } = useQuery(
    ['booking', id],
    () => queries.get(`/api/bookings`, id, { populate: '*' }).then((res) => res.data),
    { retry: false, enabled: !(typeof id === 'undefined' || id === null) }
  );

  const { data: rooms } = useQuery(
    ['room'],
    () => queries.getAll(`/api/rooms`, { populate: '*' }).then((res) => res.data),
    { retry: false }
  );

  const { data: users } = useQuery(
    ['user'],
    () => queries.getAll(`/api/users`, { populate: '*' }),
    { retry: false }
  );

  const { data: groups } = useQuery(
    ['group'],
    () => queries.getAll(`/api/groups`, { populate: '*' }).then((res) => res.data),
    { retry: false }
  );

  const { links = [], pageName = '' } = props;

  const [currentDay, setCurrentDay] = useState(moment());
  const [room, setRoom] = useState(null);

  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);
  const [isCalendar, setIsCalendar] = useState(false);

  const { data: eventsRaw, isLoading: isLoadingEvents } = useQuery(
    ['booking', 'calendar', currentDay.clone().format('YYYY-MM-DD'), room?.id],
    () =>
      queries
        .getAll(`/api/bookings`, {
          populate: '*',
          filters: {
            start: { $lte: currentDay?.clone().endOf('day').format('YYYY-MM-DDTHH:mm:ss') },
            end: { $gte: currentDay?.clone().startOf('day').format('YYYY-MM-DDTHH:mm:ss') },
            room: { id: { $eq: room?.id } }
          }
        })
        .then((res) => res?.data),
    { enabled: !!room?.id }
  );

  const events = isLoadingEvents ? [] : eventsRaw;

  // Compute fixed slots
  const nbSlots =
    configuration?.fixedDuration && room?.openingHour
      ? Math.floor(
          moment(room?.closingHour, 'HH:mm').diff(moment(room?.openingHour, 'HH:mm'), 'minutes') /
            configuration?.fixedDuration
        )
      : 0;

  const possibleStartTimes =
    configuration?.fixedDuration && room?.openingHour
      ? Array(nbSlots)
          .fill(0)
          .map((_, index) =>
            moment(room?.openingHour, 'HH:mm')
              .add(index * configuration?.fixedDuration, 'minutes')
              .format('HH:mm')
          )
      : [];

  const newHours = Array(
    moment(room?.closingHour, 'HH:mm').diff(moment(room?.openingHour, 'HH:mm'), 'hours') + 1 || 24
  )
    .fill(0)
    .map((_, index) =>
      room?.openingHour
        ? moment(room?.openingHour, 'HH:mm').add(index, 'hours').format('HH') + 'h'
        : index.toString().padStart(2, '0') + 'h'
    );

  // Syncing Booking Room
  useEffect(() => {
    if (!isLoadingBooking) {
      setRoom(booking?.room);
      setCurrentDay(moment(booking?.start)); // For DatePicker control
      setStart(moment(booking?.start).format('HH:mm')); // For Calendar and form interaction
      setEnd(moment(booking?.end).format('HH:mm')); // For Calendar and form interaction
    }
  }, [isLoadingBooking]);

  return (
    <Layout links={links} pageName={pageName}>
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 w-full overflow-auto">
        <div className="px-0 lg:px-10 pt-10 ">
          <div className="flex flex-col">
            <header className="flex flex-none items-center justify-between border-b border-gray-200 py-4 dark:border-slate-700">
              <div>
                <h1 className="text-base font-semibold leading-6 text-gray-900 dark:text-gray-500 ">
                  <time
                    dateTime={currentDay.format('YYYY-MM-DD')}
                    className="sm:hidden dark:text-white">
                    {currentDay
                      .format('DD MMM YYYY')
                      .split(' ')
                      .map((w) => capitalize(w))
                      .join(' ')}
                  </time>
                  <time
                    dateTime={currentDay.format('YYYY-MM-DD')}
                    className="hidden sm:inline dark:text-white">
                    {currentDay
                      .format('DD MMMM YYYY')
                      .split(' ')
                      .map((w) => capitalize(w))
                      .join(' ')}
                  </time>
                </h1>
                <p className="mt-1 text-sm text-gray-500">
                  {capitalize(currentDay?.format('dddd'))}
                </p>
              </div>
              <button
                type="button"
                className="md:hidden inline-flex justify-center rounded-md px-3 py-2 text-sm font-YYYY-MM-DDTHH:mm:ss-MM-DDTHH:mm:ssld text-red dark:text-gray-400 dark:hover:text-white  dark:hover:bg-slate-700"
                onClick={() => setIsCalendar(!isCalendar)}>
                {isCalendar ? translator('Voir le formulaire') : translator('Voir le calendrier')}
              </button>
              <div className="flex items-center">
                <div className="hidden md:flex relative items-center rounded-md bg-white shadow-sm md:items-stretch dark:bg-slate-900">
                  <div
                    className="pointer-events-none absolute inset-0 rounded-md ring-1 ring-inset ring-gray-300 dark:ring-white/10"
                    aria-hidden="true"
                  />
                  <button
                    type="button"
                    className="flex items-center justify-center rounded-l-md py-2 pl-3 pr-4 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50 dark:text-gray-400 dark:hover:text-white  dark:hover:bg-slate-700"
                    onClick={() => setCurrentDay(currentDay.clone().subtract(1, 'week'))}>
                    <span className="sr-only">{translator('Previous week')}</span>
                    <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                  <button
                    type="button"
                    onClick={() => setCurrentDay(moment())}
                    className="hidden px-3.5 text-sm font-semibold text-gray-900 hover:bg-gray-50 focus:relative md:block dark:text-gray-400 dark:hover:text-white  dark:hover:bg-slate-700">
                    {translator("Aujourd'hui")}
                  </button>
                  <span className="relative -mx-px h-5 w-px bg-gray-300 md:hidden" />
                  <button
                    type="button"
                    onClick={() => setCurrentDay(currentDay.clone().add(1, 'week'))}
                    className="flex items-center justify-center rounded-r-md py-2 pl-4 pr-3 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50 dark:text-gray-400 dark:hover:text-white  dark:hover:bg-slate-700">
                    <span className="sr-only">{translator('Next week')}</span>
                    <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
                {isCalendar && (
                  <div className="flex items-center justify-end">
                    <Combobox
                      className="w-32"
                      data={rooms}
                      displayValue={(r) => r?.name || ''}
                      displayOptions={(r) => r?.name || ''}
                      filter={(p, query) => p?.name?.toLowerCase().includes(query.toLowerCase())}
                      value={room}
                      setValue={setRoom}
                      noDataLabel={translator('Aucune salle ne correspond à cette recherche ...')}
                      isControlled
                    />
                  </div>
                )}
              </div>
            </header>
          </div>
          <div className="isolate flex flex-auto overflow-hidden bg-white justify-center md:justify-start dark:bg-slate-900 dark:border-slate-700 dark:divide-slate-700">
            <div
              className={
                isCalendar
                  ? 'flex-auto flex-col overflow-auto flex '
                  : 'flex-auto flex-col overflow-auto hidden md:flex'
              }>
              <div className="sticky top-0 z-10 grid flex-none grid-cols-7 bg-white text-xs text-gray-500 shadow ring-1 ring-black ring-opacity-5 md:hidden"></div>
              <div className="flex w-full flex-auto">
                <div className="w-14 flex-none bg-white ring-1 ring-gray-100 dark:bg-slate-900" />
                <div className="grid flex-auto grid-cols-1 grid-rows-1">
                  {/* Horizontal lines */}
                  <div
                    className="col-start-1 col-end-2 row-start-1 grid divide-y divide-gray-100 dark:divide-slate-700 dark:bg-slate-900"
                    style={{
                      gridTemplateRows: `repeat(${
                        2 *
                        (room?.openingHour
                          ? moment(room?.closingHour, 'HH:mm').diff(
                              moment(room?.openingHour, 'HH:mm'),
                              'hours'
                            ) + 1
                          : 24)
                      }, minmax(3.5rem, 1fr))`
                    }}>
                    <div className="row-end-1 h-7"></div>
                    {newHours?.map((h) => (
                      <>
                        <div>
                          <div className="sticky left-0 z-20 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                            {h}
                          </div>
                        </div>
                        <div />
                      </>
                    ))}
                  </div>

                  {/* Events */}
                  <ol
                    className="col-start-1 col-end-2 row-start-1 grid grid-cols-1"
                    style={{
                      gridTemplateRows: `1.75rem repeat(${
                        room?.openingHour
                          ? 12 *
                            (moment(room?.closingHour, 'HH:mm').diff(
                              moment(room?.openingHour, 'HH:mm'),
                              'hours'
                            ) +
                              1)
                          : 12 * 24
                      }, minmax(0, 1fr)) auto`
                    }}>
                    {events?.map((e, index) => (
                      <BookingCell
                        key={`cell_${index}`}
                        booking={e}
                        // firstDay={currentDay.clone().startOf('isoWeek')}
                        openingHourInt={moment(room?.openingHour, 'HH:mm').hours()}
                        onClick={(event) => {
                          event.preventDefault();
                        }}
                      />
                    ))}
                    {possibleStartTimes?.map((s, index) => {
                      const element = {
                        name: translator('Free'),
                        start: moment(`${currentDay?.format('YYYY-MM-DD')}T${s}:00`).format(
                          'YYYY-MM-DDTHH:mm:ss'
                        ),
                        end: moment(`${currentDay?.format('YYYY-MM-DD')}T${s}:00`)
                          .add(configuration.fixedDuration, 'minutes')
                          .format('YYYY-MM-DDTHH:mm:ss'),
                        user: { username: 'TBD' }
                      };

                      return (
                        !events
                          ?.map(
                            (e) =>
                              (moment(element.start).isSameOrAfter(moment(e.start)) &&
                                moment(element.start).isBefore(moment(e.end))) ||
                              (moment(element.end).isAfter(moment(e.start)) &&
                                moment(element.end).isSameOrBefore(moment(e.end))) ||
                              (moment(element.start).isBefore(moment(e.start)) &&
                                moment(element.end).isAfter(moment(e.end)))
                          )
                          .some(Boolean) && (
                          <BookingCell
                            key={`cellFree_${index}`}
                            booking={element}
                            // firstDay={currentDay.clone().startOf('isoWeek')}
                            openingHourInt={moment(room?.openingHour, 'HH:mm').hours()}
                            onClick={(event) => {
                              event.preventDefault();
                              setStart(moment(element.start).format('HH:mm'));
                            }}
                          />
                        )
                      );
                    })}
                  </ol>
                </div>
              </div>
            </div>

            <div
              className={`${
                isCalendar && 'hidden '
              } w-full md:block md:w-1/2 mt-6 border-l border-gray-100 `}>
              <EditForm
                title={translator('Réservations')}
                description={translator(
                  "Ce formulaire permets de modifier les informations liées aux réservations, tel que l'utilisateur, le terrain ou bien les horaires."
                )}>
                <div className="block col-span-full px-8 py-2 space-y-5">
                  <DatePicker selectedDay={currentDay} setSelectedDay={setCurrentDay} />
                </div>
                <input type="hidden" name="id" defaultValue={booking?.id} />
                <input type="hidden" name="room" value={room?.id || null} />
                <InputText
                  className="col-span-full"
                  name="name"
                  label={translator('Description de la réservation *')}
                  defaultValue={booking?.name}
                  required
                />
                <Combobox
                  className="col-span-full"
                  label={translator('Salle *')}
                  data={rooms}
                  displayValue={(r) => r?.name || ''}
                  displayOptions={(r) => r?.name || ''}
                  filter={(p, query) => p?.name?.toLowerCase().includes(query.toLowerCase())}
                  value={room}
                  setValue={setRoom}
                  noDataLabel={translator('Aucune salle ne correspond à cette recherche ...')}
                  isControlled
                  required
                />
                <Combobox
                  className="col-span-full"
                  name="user"
                  label={translator('Users *')}
                  data={users}
                  displayValue={(r) => r?.username || ''}
                  displayOptions={(r) => r?.username || ''}
                  filter={(p, query) => p?.username?.toLowerCase().includes(query.toLowerCase())}
                  defaultValue={booking?.user}
                  noDataLabel={translator('Aucun username ne correspond à cette recherche ...')}
                  required
                />
                {ignoreGroups ? (
                  <input type="hidden" name="group" defaultValue={1} />
                ) : (
                  <Combobox
                    className="col-span-full"
                    name="group"
                    label={translator('Groupe *')}
                    data={groups}
                    displayValue={(r) => r?.name || ''}
                    displayOptions={(r) => r?.name || ''}
                    filter={(p, query) => p?.name?.toLowerCase()?.includes(query.toLowerCase())}
                    defaultValue={booking?.group}
                    noDataLabel={translator('Aucun username ne correspond à cette recherche ...')}
                    required
                  />
                )}
                <input
                  type="hidden"
                  name="start"
                  value={`${currentDay?.format('YYYY-MM-DD')}T${start}:00`}
                />
                <input
                  type="hidden"
                  name="end"
                  value={`${currentDay?.format('YYYY-MM-DD')}T${
                    configuration?.fixedDuration > 0 && start
                      ? moment(start, 'HH:mm')
                          .add(configuration?.fixedDuration, 'minutes')
                          .format('HH:mm')
                      : end
                  }:00`}
                />
                <div className="col-span-full flex items-center justify-between">
                  {configuration?.fixedDuration > 0 && (
                    <Combobox
                      className="w-1/2 pr-8"
                      label={translator('Début de la réservation *')}
                      data={possibleStartTimes}
                      valueKey={(data) => data}
                      displayValue={(r) => r || ''}
                      displayOptions={(r) => r || ''}
                      value={start}
                      setValue={setStart}
                      isControlled
                      required
                    />
                  )}
                  {configuration?.fixedDuration === 0 && (
                    <InputText
                      className="w-1/2 pr-8"
                      label={translator('Début de la réservation *')}
                      type="time"
                      step={1800}
                      value={start}
                      onChange={(e) => setStart(e.target.value)}
                      required
                    />
                  )}
                  <InputText
                    className="w-1/2 pl-8"
                    label={translator('Fin de la réservation *')}
                    type="time"
                    step={1800}
                    value={
                      configuration?.fixedDuration > 0 && start
                        ? moment(start, 'HH:mm')
                            .add(configuration?.fixedDuration, 'minutes')
                            .format('HH:mm')
                        : end
                    }
                    onChange={(e) => configuration?.fixedDuration === 0 && setEnd(e.target.value)}
                    required
                    disabled={configuration?.fixedDuration > 0}
                  />
                </div>

                <Combobox
                  className="col-span-full"
                  name="multiple.participants"
                  label={translator('Participants')}
                  data={users}
                  displayValue={(r) => r?.username || ''}
                  displayOptions={(r) => r?.username || ''}
                  filter={(p, query) => p?.username?.toLowerCase().includes(query.toLowerCase())}
                  defaultValue={booking?.participants}
                  noDataLabel={translator('Aucun username ne correspond à cette recherche ...')}
                  multiple
                />
                <InputText
                  className="col-span-full"
                  name="frequency"
                  label={translator('Fréquence *')}
                  defaultValue={booking?.frequency}
                  required
                />
                <InputText
                  className="col-span-full"
                  name="nbOccurrences"
                  label={translator("Nombre d'occurrence *")}
                  defaultValue={booking?.nbOccurrences}
                  required
                />
              </EditForm>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default BookingEdit;
