import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

import { createBrowserRouter, redirect, RouterProvider } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { DarkModeProvider } from '@components';
import { Render } from '@layouts';
import { Configuration, Translator } from '@context';
import {
  ClipboardIcon,
  CalendarDaysIcon,
  UsersIcon,
  ListBulletIcon,
  AdjustmentsHorizontalIcon,
  SquaresPlusIcon,
  BanknotesIcon
  // CreditCardIcon
} from '@heroicons/react/24/outline';

import { AnimatePresence } from 'framer-motion';
import {
  Auth,
  NotFound,
  Booking,
  BookingEdit,
  Room,
  RoomEdit,
  User,
  UserEdit,
  Profile,
  Group,
  GroupEdit,
  Credit,
  CreditEdit,
  Calendar
} from '@pages';
import '@root/polyfill';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false
    }
  }
});

const links = [
  { name: 'Visualisation', isTitle: true, isMobile: false },
  {
    name: 'Calendrier',
    pageName: 'calendar',
    icon: CalendarDaysIcon,
    renderPage: Calendar,
    isMobile: false
  },
  { name: 'Ressources', isTitle: true, isMobile: false },
  {
    name: 'Réservations',
    pageName: 'booking',
    icon: ListBulletIcon,
    renderPage: Booking,
    renderEdit: BookingEdit,
    isMobile: true
  },
  {
    name: 'Salles',
    pageName: 'room',
    icon: ClipboardIcon,
    renderPage: Room,
    renderEdit: RoomEdit,
    isMobile: true
  },
  {
    name: 'Utilisateurs',
    pageName: 'user',
    icon: UsersIcon,
    renderPage: User,
    renderEdit: UserEdit,
    isMobile: true
  },
  {
    name: 'Groupes',
    icon: SquaresPlusIcon,
    pageName: 'group',
    renderPage: Group,
    renderEdit: GroupEdit,
    isMobile: true
  },
  {
    name: 'Crédits',
    icon: BanknotesIcon,
    pageName: 'credit',
    renderPage: Credit,
    renderEdit: CreditEdit,
    isMobile: true
  },
  { name: 'Configuration', isTitle: true, isMobile: false },
  {
    name: 'Profil',
    icon: AdjustmentsHorizontalIcon,
    pageName: 'profile',
    renderPage: Profile,
    isMobile: false
  }
];

const router = createBrowserRouter([
  { path: '/', loader: async () => redirect('/calendar') },
  {
    path: '/:pageName',
    element: (
      <Auth>
        <Render links={links.map((o) => ({ ...o, render: o?.renderPage }))} />
      </Auth>
    )
  },
  {
    path: '/edit/:pageName/:id?',
    element: (
      <Auth>
        <Render links={links.map((o) => ({ ...o, render: o?.renderEdit }))} />
      </Auth>
    )
  },
  {
    path: '*',
    element: <NotFound />
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <DarkModeProvider>
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <Configuration.Provider>
          <Translator.Provider>
            <AnimatePresence mode="wait">
              <RouterProvider router={router} />
            </AnimatePresence>
          </Translator.Provider>
        </Configuration.Provider>
      </QueryClientProvider>
    </React.StrictMode>
  </DarkModeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
